import React from 'react';
import {Layout} from "../components/Layout";
import {BackgroundLine} from "../components/BackgroundLine";
import {graphql} from "gatsby";
import {Sections} from "../components/Sections";

export const query = graphql`
    query Privacy($locale: String!) {
        page: strapiSpecialPage(locale: {eq: $locale}, slug: {eq: "privacy"}) {
            title
            sections {
                ...AllSectionsContent
            }
            seo {
                ...Seo
            }
        }
    }
`
export default function PrivacyPolicyPage({data, pageContext}) {
    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   title={data.page.title}
                   seo={data.page.seo}
                   jobsCount={pageContext.jobsCount}
                   companyData={pageContext.companyData}
                   background={<>
                       <BackgroundLine mobileOn={false}/>
                   </>}
                   lang={pageContext.lang}>
        <Sections
            sections={data.page.sections}
        />
    </Layout>;
}
